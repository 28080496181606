


(function ($) {



    $.fn.chat = function () {
        var el = this;
        var button = this.find('#submit-message');
        var text = this.find('#chat-text');
        var container = this.find('#chat-messages');
        //var event_id = el.find('#exercise-video').attr('data-exercise-id');
        const config = {
            user: el.attr('data-user-id'),
            endpoint: el.attr('data-endpoint'),
        };

        var last_count = 0;

        var loadMessages = function () {
            $.ajax({
                type: 'GET',
                url: config.endpoint,
                data: {},
                success: function (data) {
                    container.html('');
                    var this_count = 0;
                    $.each(data.messages, function(index, msg) {
                        let className = "message-container ";
                        if (msg.my_sent){
                            className += "mine ";
                        }
                        if (msg.seen_at){
                            className += "seen ";
                        }
                        container.append('<li ><div class = "'+className+'"><div class = "time">'+msg.created_at+'</div><div class = "sender">'+msg.sender_name+'</div><div class = "receiver">'+msg.receiver_name+'</div>'+msg.message+'</div></li>')
                         this_count++;
                    });
                    
                    if (this_count !== last_count){
                        $("#chat-messages-container").animate({ scrollTop: container.height() }, 100);
                    }
                    last_count = this_count;
                }
            });
        };
        
        var sendMessage = function () {
            if (text.val()){
                $.ajax({
                    type: 'POST',
                    url: config.endpoint,
                    data: {message:text.val()},
                    success: function (data) {
                        text.val('');
                        loadMessages();
                    }
                });
            }
        };
        
        loadMessages();
        
        setInterval(function(){ loadMessages(); }, 10000);
        
        button.on('click', function(){
            sendMessage();
        });
        return this;
    };

}(jQuery));

