import ClassicEditor from './../ckeditor5/build/ckeditor';


(function ($) {
    $.fn.editor = function () {

        if ($(this).length > 0) {
            this.each(function() {
                var el = $(this);
                ClassicEditor
                        .create(el[0], {
                            toolbar: {
                                items: [
                                    'heading', '|',
                                    'fontsize', '|',
                                    'alignment', '|',
                                    'fontColor', '|',
                                    'bold', 'italic', 'strikethrough', 'subscript', '|',
                                    'link', '|',
                                    'outdent', 'indent', '|',
                                    'bulletedList', 'numberedList', '|',
                                    'code', '|',
                                    'insertTable', '|',
                                    'uploadImage', 'blockQuote', '|',
                                    'undo', 'redo',
                                ],
                                shouldNotGroupWhenFull: true,
                            },
                            image: {
                                // Configure the available styles.
                                styles: [
                                    'alignLeft', 'alignCenter', 'alignRight'
                                ],

                                // Configure the available image resize options.
                                resizeOptions: [
                                    {
                                        name: 'resizeImage:original',
                                        label: 'Original',
                                        value: null
                                    },
                                    {
                                        name: 'resizeImage:50',
                                        label: '50%',
                                        value: '50'
                                    },
                                    {
                                        name: 'resizeImage:75',
                                        label: '75%',
                                        value: '75'
                                    }
                                ],

                                // You need to configure the image toolbar, too, so it shows the new style
                                // buttons as well as the resize buttons.
                                toolbar: [
                                    'imageStyle:alignLeft', 'imageStyle:alignCenter', 'imageStyle:alignRight',
                                    '|',
                                    'resizeImage',
                                    '|',
                                    'imageTextAlternative'
                                ]
                            },
                            heading: {
                                options: [
                                    {model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph'},
                                    {model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1'},
                                    {model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2'}
                                ]
                            },
                            simpleUpload: {
                                // The URL that the images are uploaded to.
                                uploadUrl: el.attr('data-upload'),
                                // Enable the XMLHttpRequest.withCredentials property.
                                withCredentials: true,
                                // Headers sent along with the XMLHttpRequest to the upload server.
                                headers: {
                                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
                                    'Authorization': 'Bearer ' + $('meta[name="token"]').attr('content'),
                                    'Accept': 'application/json',
                                }
                            },
                            link: {
                                // Automatically add target="_blank" and rel="noopener noreferrer" to all external links.
                                addTargetToExternalLinks: true,
                            }
                        })
                        .catch(error => {
                            console.error(error);
                        });
            });
        }
    };

}(jQuery));