(function ($) {



    $.fn.video = function (Player, swal) {
        var el = this;
        var event_id = el.find('#exercise-video').attr('data-exercise-id');
        var exercise_url = el.find('#exercise-video').attr('data-exercise-url');
        var started_at = el.find('#exercise-video').attr('data-started-at');
        var ended_at = el.find('#exercise-video').attr('data-ended-at');
        var video_time = el.find('#exercise-video').attr('data-video-time');
        var button = el.find('#exercise-start');
        var stop_button = el.find('#exercise-stop');
        var video_container = el.find('#exercise-video');
        var video = el.find('#exercise-video iframe');
        var video_duration = null;
        var current_duration = null;
        var playing = false;

        if (video.length == 0) {
            return this;
        }
        var player = new Player(video);
        
        player.pause();

        var playerSeek = function (time) {
            player.setCurrentTime(time).then(function (seconds) {
                //console.log('Seek to ' +seconds);
            }).catch(function (error) {
                switch (error.name) {
                    case 'RangeError':
                        // The time is less than 0 or greater than the video's duration
                        break;

                    default:
                        // Some other error occurred
                        break;
                }
            });
        };

        var init = function () {

            player.getDuration().then(function (duration) {
                video_duration = duration;
                if (video_time > 10 && video_time < (video_duration - 10)) {
                    playerSeek(video_time);
                    player.pause();
                }
            }).catch(function (error) {
                // an error occurred
            });

            /*
            button.on('click', function () {
                start();
            });
            */
           
            stop_button.on('click', function () {
                player.pause();
                swal.fire({
                    title: "Ar tirkai norite baigti treniruotę anskčiau laiko?",
                    text: "",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: 'Taip!',
                    cancelButtonText: 'Ne',
                }).then((isConfirm) => {
                    if (isConfirm.isConfirmed) {
                        stop();
                    } else {
                        player.play();
                    }
                });
            });

            player.on('play', function () {
                //console.log('play');
                playing = true;
            });

            player.on('pause', function () {
                //console.log('pause');
                playing = false;
            });

            player.on('ended', function () {
                //console.log('ended');
                playing = false;
                player.exitFullscreen();
                if ($.trim(ended_at) === '') {
                    stop();
                }
            });


            video_container.show();
        };



        var trackPlayback = function () {
            if (playing === true) {
                player.getCurrentTime().then(function (seconds) {
                    $.ajax({
                        type: 'POST',
                        url: $('meta[name="app-url"]').attr('content') + "/api/timetables/" + event_id + "/track",
                        data: {current_time: seconds, video_duration: video_duration},
                        success: function (data) {
                            //console.log('tracked');
                        },
                        error: function (jqXHR, textStatus, errorThrown) {

                        }
                    });
                });

            }
        };

        var intervalID = setInterval(trackPlayback, 5000);

        var rateModal = function () {


            var rate_container = $("<label>", {id: "exercise-rate-container"});
            rate_container.append('<p>Kaip vertinate?</p>');
            rate_container.append(
                    $("<input>", {
                        class: "rate rate-1",
                        type: "radio",
                        value: 1,
                        name: "rate"
                    })
                    );

            rate_container.append(
                    $("<input>", {
                        class: "rate rate-2",
                        type: "radio",
                        value: 2,
                        name: "rate"
                    })
                    );

            rate_container.append(
                    $("<input>", {
                        class: "rate rate-3",
                        type: "radio",
                        value: 3,
                        name: "rate"
                    })
                    );

            rate_container.append(
                    $("<input>", {
                        class: "rate rate-4",
                        type: "radio",
                        value: 4,
                        name: "rate"
                    })
                    );

            rate_container.append(
                    $("<input>", {
                        class: "rate rate-5",
                        type: "radio",
                        value: 5,
                        name: "rate"
                    })
                    );

            swal.fire({
                title: "Sveikiname užbaigus treniruotę!",
                //text: "Kaip vertinate?",
                icon: "success",
                showCancelButton: true,
                confirmButtonText: 'Įvertinti!',
                cancelButtonText: 'Nenoriu',
                html: rate_container,
                preConfirm: () => {
                    return [
                        $("#exercise-rate-container input:checked").val(),
                        ""
                    ]
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    rate(result.value);
                } else {
                    //reload
                    location.href = exercise_url;
                }
            });

        };

        var showError = function (error) {
            swal({
                icon: 'error',
                title: 'Oops...',
                text: error
            });
        };

        var start = function () {
            $.ajax({
                type: 'PUT',
                url: $('meta[name="app-url"]').attr('content') + "/api/timetables/" + event_id,
                data: {action: 'start'},
                success: function (data) {
                    if (data === 'ok') {
                        video_container.show();
                        player.play();
                        button.hide();
                        stop_button.show();
                    } else if (data.error !== undefined) {
                        showError(data.error);
                    }
                }
            });
        };

        var stop = function () {
            $.ajax({
                type: 'PUT',
                url: $('meta[name="app-url"]').attr('content') + "/api/timetables/" + event_id,
                data: {action: 'stop', video_duration: video_duration},
                success: function (data) {
                    if (data === 'ok') {
                        rateModal();
                    }
                }
            });
        };

        var rate = function (result) {
            $.ajax({
                type: 'PUT',
                url: $('meta[name="app-url"]').attr('content') + "/api/timetables/" + event_id,
                data: {action: 'rate', value: result[0], comment: result[1]},
                success: function (data) {
                    if (data === 'ok') {
                    }
                    location.href = exercise_url;
                }
            });
        };

        init();
        return this;
    };

}(jQuery));