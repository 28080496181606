require('./bootstrap');

import swal2 from 'sweetalert2';

import Player from '@vimeo/player';


require('../../node_modules/jquery-datetimepicker/build/jquery.datetimepicker.full.js');

require('./evo-calendar');

require('./admin/plan');
require('./admin/user-times');

require('./video');
//require('./zoom');
require('./twilio');
require('./chat');
require('./admin/ckeditor');

require('./jquery.cookie');
require('./cookieList');


import dt from 'datatables.net-bs4';

const swal = swal2.mixin({
    customClass: {
        confirmButton: 'btn btn-primary m-2',
        cancelButton: 'btn btn-danger m-2'
    },
    buttonsStyling: false
});

function deleteConfirm(event, form) {
    event.preventDefault(); // prevent form submit
    //var form = event.target.form; // storing the form
    swal.fire({
        title: "Ar esate tikri?",
        text: "Pašalinus dings visa susijusi infromacija",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: 'Taip!',
        cancelButtonText: 'Atšaukti',
    }).then((result) => {
        if (result.isConfirmed) {
            form.submit();          // submitting the form when user press yes
        }
    });
}



jQuery('.calendar-add').on('click', function () {
    $('#planModal').attr('data-week', $(this).attr('data-week'));
    $('#planModal').attr('data-day', $(this).attr('data-day'));
    $('#planModal').modal('show');

});


$(document).ready(function () {
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
            'Authorization': 'Bearer ' + $('meta[name="token"]').attr('content'),
            'Accept': 'application/json',
        },
        error: function (jqXHR, textStatus, errorThrown) {
            if (jqXHR.status == 404) {
                swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Objektas nerastas!'
                });
            } else {
                swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: textStatus
                });
            }
        }
    });

    $('.delete-action').on('click', function (ev) {
        var form = $(this).parent('form');
        deleteConfirm(ev, form);
    });

    $.datetimepicker.setLocale('lt');

    $('input#time, input#start_time, input#end_time').datetimepicker({
        datepicker: false,
        format: 'H:i',
        minTime: '6:00',
        maxTime: '22:00',
    });

    $('input.timepicker').datetimepicker({
        datepicker: false,
        format: 'H:i',
    });

    $('input.datepicker').datetimepicker({
        datepicker: true,
        format: 'Y-m-d H:i',
    });

    $('input.availabledatepicker').datetimepicker({
        timepicker: false,
        datepicker: true,
        format: 'Y-m-d',
        closeOnDateSelect: true,
    });


    var consultation_logic = function (cd, $input) {
        if (cd !== null) {
            var times = JSON.parse($input.attr('data-allowed-times'));
            var date = cd.getFullYear() + '-' + ('0' +(cd.getMonth()+1)).slice(-2) + '-' + ('0' +cd.getDate()).slice(-2);
            
            var allowed_times = times[date];
            if (allowed_times !== undefined){
                if (allowed_times.length) {
                    this.setOptions({
                        allowTimes: allowed_times,
                        maxTime: false,
                    });
                } else {
                    this.setOptions({
                        maxTime: '1:00',
                        allowTimes: []
                    });
                }
            }
        }
    };

    var consultation_init = function (currentDateTime, $input) {

        this.setOptions({
            allowDates: $input.attr('data-allowed-dates')
        });
    };
    if ($('input.consultation_time').length> 0){
        $('input.consultation_time').datetimepicker({
            datepicker: true,
            format: 'Y-m-d H:i',
            lang: 'lt',
            inline: true,
            minDate: 0,
            dayOfWeekStart: 1,
            defaultSelect: false,
            yearStart: '2021',
            allowDates: JSON.parse($('input.consultation_time').attr('data-allowed-dates')),
            formatDate: 'Y-m-d',
            onChangeDateTime: consultation_logic
        });
    }

    $('input.datefrom').datetimepicker({
        timepicker: false,
        format: 'Y-m-d',
        closeOnDateSelect: true,
        onSelectDate: function (dp, $input) {
            $('input.dateto').datetimepicker({minDate: $input.val()});
        },
    });

    if ($('input.dateto').val()) {
        $('input.datefrom').datetimepicker({maxDate: $('input.dateto').val()});
    }

    $('input.dateto').datetimepicker({
        timepicker: false,
        format: 'Y-m-d',
        closeOnDateSelect: true,
        onSelectDate: function (dp, $input) {
            $('input.datefrom').datetimepicker({maxDate: $input.val()});
        },
    });

    if ($('input.datefrom').val()) {
        $('input.dateto').datetimepicker({minDate: $('input.datefrom').val()});
    }

    $('.exercise-container').on('click', '.delete-plan', function (ev) {

        var plan = $(this).closest('.day-plan');
        var id = plan.attr('data-id');
        $.ajax({
            type: 'DELETE',
            url: $('meta[name="app-url"]').attr('content') + "/api/days/" + id,
            data: {},
            success: function (data) {
                if (data === 'ok') {
                    plan.fadeOut(function () {
                        $(this).remove();
                    });
                }
            }
        });
    });



    $('.calendar-add').on('click', function () {
        var modal = $('#planModal');
        var modalTitle = modal.find('#planModalLabel');
        modalTitle.html(modalTitle.attr('data-add-title'));
        modal.find('#exercise').val('');
        modal.find('#time').val('');
        modal.find('#day_id').val('');
        modal.attr('data-day', $(this).attr('data-day'));
        modal.attr('data-week', $(this).attr('data-week'));
        $('#planModal').modal('show');

    });

    $('.plan-modal-add-btn').on('click', function (ev) {

        var modal = $('#planModal');
        var program = modal.attr('data-program');
        var day = modal.attr('data-day');
        var week = modal.attr('data-week');
        var exercise = modal.find('#exercise').val();
        var time = modal.find('#time').val();
        var day_id = modal.find('#day_id').val();

        $.ajax({
            type: 'POST',
            url: $('meta[name="app-url"]').attr('content') + "/api/days",
            data: {program: program, exercise: exercise, time: time, day: day, week: week, id: day_id},
            success: function (data) {
                if (data !== 'error') {
                    $('#exercise-container-' + week + day).fadeOut(function () {
                        $(this).html(data).fadeIn()
                    });
                    $('#planModal').modal('hide');
                }
            }
        });
    });

    $('.exercise-container').on('click', '.edit-plan', function (ev) {

        var add = $(this).closest('.calendar-day').find('.calendar-add');
        var plan = $(this).closest('.day-plan');
        var modal = $('#planModal');
        var modalTitle = modal.find('#planModalLabel');
        modal.find('#exercise').val(plan.attr('data-exercise-id'));
        modal.find('#time').val(plan.attr('data-time'));
        modal.find('#day_id').val(plan.attr('data-id'));
        modal.attr('data-day', add.attr('data-day'));
        modal.attr('data-week', add.attr('data-week'));
        modalTitle.html(modalTitle.attr('data-edit-title'));
        $('#planModal').modal('show');
    });


    $('.datatable').DataTable({
        language: {
            "decimal": "",
            "emptyTable": "Nėra duomenų",
            "info": "Rodo nuo _START_ iki _END_ iš _TOTAL_ įrašų",
            "infoEmpty": "Rodo nuo 0 iki 0 iš 0 įrašų",
            "infoFiltered": "(išfiltruota iš _MAX_ įrašų)",
            "infoPostFix": "",
            "thousands": ",",
            "lengthMenu": "Rodyti _MENU_ įrašų",
            "loadingRecords": "Kraunasi...",
            "processing": "Apdorojama...",
            "search": "Paieška:",
            "zeroRecords": "Įrašų nerasta",
            "paginate": {
                "first": "Pirmas",
                "last": "Paskutinis",
                "next": "Sekantis",
                "previous": "Ankstesnis"
            },
            "aria": {
                "sortAscending": ": rūšiuoti didėjančia tvarka",
                "sortDescending": ": rūšiuoti mažėjančia tvarka"
            }
        }
    });

    $("#exercise-container").video(Player, swal);

    //$("#zoom-container").zoom();
    $("#twilio-container").twilio();

    $(".editor").editor();

    $('.notify-message .close').on('click', function () {

        var message = $(this).parent('.notify-message');
        var id = message.attr('data-id');
        var cookie = $.fn.cookieList("closedMessages");
        cookie.add(id);
        message.fadeOut();
    });


    $('[data-copy]').on('click', function () {
        var obj = $('#' + $(this).attr('data-copy'));
        obj[0].select();
        obj[0].setSelectionRange(0, 99999);
        document.execCommand("copy");
        var old = $(this).attr('title');
        $(this).tooltip('hide');
        $(this).attr('data-original-title', 'Nukopijuota!');
        $(this).tooltip('show');
    });

});


$(function () {
    $('[data-toggle="tooltip"]').tooltip()
});