jQuery('document').ready(function($){
    $('.plan-program-add').on('click', function () {
       $('#planProgramModal').modal('show');

    });
    
    $('.plan-programs').on('click','.delete-plan-program', function (ev) {
        var modal = $('#planProgramModal');
        var plan = modal.attr('data-plan');
        var id = $(this).attr('data-program');
        var program = $(this).closest('li');
        $.ajax({
           type:'DELETE',
           url:$('meta[name="app-url"]').attr('content')+"/api/plan/programs/" + plan + "/" + id,
           data:{},
           success:function(data){
              if (data === 'ok'){
                  program.fadeOut(function(){$(this).remove();});
              }
           }
        });
    });
    
    $('.plan-program-modal-add-btn').on('click', function (ev) {
        
        var modal = $('#planProgramModal');
        var plan = modal.attr('data-plan');
        var program = modal.find('#program').val();
        var order = modal.find('#order').val();
        $.ajax({
           type:'POST',
           url:$('meta[name="app-url"]').attr('content')+"/api/plan/programs",
           data:{program:program,plan:plan,order:order},
           success:function(data){
              if (data !== 'error'){
                  $('.plan-programs').fadeOut(function(){ $(this).html(data).fadeIn()});
                  $('#planProgramModal').modal('hide');
              }
           }
        });
    });
});