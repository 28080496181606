
var twilio = require('twilio-video');

(function ($) {



    $.fn.twilio = function () {
        var el = this;
        //var event_id = el.find('#exercise-video').attr('data-exercise-id');
        const config = {
            token: el.attr('data-token'),
            room: el.attr('data-room'),
            texts: {
                pending: "Laukiama pašnekovo",
                connecting: "Jungiamasi",
                disconnected: "Neprisijungta"
            },
            participants: 0,
            connected: false,
            connecting: false,
            muted: false
        };

        var hideButtons = function () {
            el.find('.buttons .btn').hide();
        };

        var main_room = null;

        var connect = function () {
            config.connecting = true;
            updateStatus();
            main_room = twilio.connect(config.token, {
                name: config.room,
                audio: true,
                video: {width: 640},
            }).then(room => {
                main_room = room;
                config.connecting = false;
                config.connected = true;
                console.log(`Connected to Room: ${room.name}`);
                const localParticipant = room.localParticipant;
                participantConnected(room.localParticipant, true);
                room.participants.forEach(participant => {
                    if (participant.sid != localParticipant.sid) {
                        participantConnected(participant, false);
                    }
                });
                room.on('participantConnected', participant => {
                    participantConnected(participant, false);
                });

                room.on('participantDisconnected', function (participant) {
                    console.log("Disconnected: '" + participant.identity + "'");
                    participantDisconnected(participant);
                });
                
                        /*
                room.on('trackAdded', function (div, track) {
                    trackAdded(div, track);
                });
                room.on('trackRemoved', trackRemoved);*/
            });
        };
        var participantConnected = function (participant, local) {
            console.log('Participant "%s" connected', participant.identity);

            const div = document.createElement('div');
            div.id = participant.sid;
            if (local === true) {
                div.setAttribute("class", "my-video");
            } else {
                div.setAttribute("class", "video-container");
            }
            div.setAttribute("style", "float: left; margin: 10px;");
            div.innerHTML = "<div class ='video-name'>" + participant.identity + "</div>";
            participant.tracks.forEach(function(participant) {
                console.log(participant.track);
                if (participant.track) {
                    trackAdded(div, participant.track);
                }
            });
            
             participant.on('trackSubscribed', track => trackAdded(div, track));
            participant.on('trackUnsubscribed', trackRemoved);

            

            el.append(div);
            config.participants++;
            updateStatus();
        };


        var disconnect = function () {
            main_room.participants.forEach(participant => {
                participantDisconnected(participant);
            });
            el.find('.my-video').remove();
            main_room.disconnect();
            main_room = null;
            config.connected = false;
            config.participants = 0;
            updateStatus();
        };

        var participantDisconnected = function (participant) {
            console.log('Participant "%s" disconnected', participant.identity);
            participant.tracks.forEach(publication => {
                if (publication.track) {
                    trackRemoved(publication.track);
                }
            });
            document.getElementById(participant.sid).remove();
            config.participants--;
            if (config.participants < 0) {
                config.participants = 0;
            }
            updateStatus();
        };

        var trackAdded = function (div, track) {
            console.log("Adding track");
            div.appendChild(track.attach());
            console.log(track);
            var video = div.getElementsByTagName("video")[0];
        };

        var trackRemoved = function (track) {
            track.detach().forEach(function (element) {
                element.remove();
            });
        };

        var updateStatus = function () {
            if (config.participants == 1) {
                el.find('.status').html(config.texts.pending).show();
            } else if (config.participants == 2) {
                el.find('.status').hide();
            }

            if (config.connecting === true) {
                hideButtons();
                el.find('.status').html(config.texts.connecting).show();
            } else if (config.connected === true) {
                hideButtons();
                el.find('#twilio-end').show();
            } else {
                hideButtons();
                el.find('#twilio-call').show();
                el.find('.status').html(config.texts.disconnected).show();
            }

            if (config.connected) {
                if (config.muted) {
                    //el.find('#twilio-mute').hide();
                   // el.find('#twilio-unmute').show();
                } else {
                    //el.find('#twilio-unmute').hide();
                    //el.find('#twilio-mute').show();
                }
            }
        };

        updateStatus();

        el.find('#twilio-call').on('click', function () {
            if (config.connected === false) {
                connect();
            }
        });

        el.find('#twilio-end').on('click', function () {
            if (config.connected === true) {
                disconnect();
            }
        });

        return this;
    };

}(jQuery));

