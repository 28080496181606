jQuery('document').ready(function($){
    
    $('.consultant-calendar-container .time-add').on('click', function () {
        var modal = $('#timeModal');
        var modalTitle = modal.find('#timeModalLabel');
        modalTitle.html(modalTitle.attr('data-add-title'));
        modal.find('#start_time').val('');
        modal.find('#end_time').val('');
        modal.find('#day_id').val('');
        modal.attr('data-day', $(this).attr('data-day'));
        $('#timeModal').modal('show');

    });

    $('.time-modal-add-btn').on('click', function (ev) {

        var modal = $('#timeModal');
        var date = modal.find('#date').val();
        var start_time = modal.find('#start_time').val();
        var end_time = modal.find('#end_time').val();
        
        $.ajax({
            type: 'POST',
            url: $('meta[name="app-url"]').attr('content') + "/api/times",
            data: {end_time: end_time, start_time: start_time, date: date},
            success: function (data) {
                if (data !== 'error') {
                    $('#timeModal').modal('hide');
                    $('#consultant-calendar').evoCalendar('addCalendarEvent', data);
                }
            }
        });
    });
    
    $('#consultant-calendar').on('click', '.remove-event', function (ev) {
        ev.preventDefault();
        var id = $(this).attr('data-id');
        $.ajax({
            type: 'DELETE',
            url: $('meta[name="app-url"]').attr('content') + "/api/times/" + id,
            data: {},
            success: function (data) {
                if (data === 'ok') {
                   $('#consultant-calendar').evoCalendar('removeCalendarEvent', [""+id]);
                }
            }
        });
    });

});